.navbar {
  width: 100%;
  background: var(--color-neutral-bg);
  transition: 0.5s;
  top: -100px;
  &.sticky {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.5s;

    box-shadow: 0px 3px 3px var(--grey-lighter);

    nav {
      height: 7rem;
      .nav--left {
        .logo {
          height: 7rem;
        }
      }
    }
  }

  nav {
    z-index: 100;
    max-width: 130rem;
    width: 100%;
    margin: auto;
    height: 9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu {
      font-size: 3.5rem;
      color: var(--black);
      display: none;
      &:hover {
        color: #acacac;
      }
    }
    .nav--left {
      width: 100%;
      display: flex;
      flex: 1;
      gap: 3rem;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      .logo {
        height: 9rem;
        padding: 0.5rem;
        display: inline-block;
        img {
          height: 100%;
        }
      }
      @media (max-width: 300px) {
        .center {
          width: 100%;
          order: 3;
        }

        .left {
          width: 50%;
        }
        .right {
          width: 50%;
          order: 2;
        }
        flex-wrap: wrap;
        @media (max-width: 300px) {
          flex-wrap: wrap;

          .search {
            order: 3;
          }

          .menu {
            order: 2;
          }
        }
      }
    }

    .navlinks {
      display: flex;

      gap: 2.5rem;
      height: 100%;

      &--link {
        height: 100%;
        display: flex;
        align-items: center;
        list-style: none;
        font-size: 1.4rem;
        color: var(--black);
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        &:hover {
          color: #048bf3;
        }
        span {
          height: 100%;

          display: flex;
          align-items: center;
        }
        a {
          color: var(--black);
          &:hover {
            color: #048bf3;
          }
        }

        .navActive {
          color: #048bf3;
          text-decoration: underline;
        }
        // for sub link
        &__sub {
          z-index: 200;
          background: #052190;
          visibility: hidden;
          position: absolute;
          top: 100%;
          left: 50%;

          transform: translateX(-50%);
          width: 15rem;

          font-size: 1.3rem;
          li {
            padding: 0.5rem 1rem;
            a {
              color: white;
              &:hover {
                color: #048bf3;
              }
            }
            &:not(:last-child) {
              border-bottom: 1px solid rgb(80, 79, 79);
            }
          }

          &.active {
            visibility: visible;
          }
        }
      }
      .navlinks--link:has(a.subNavActive) > span {
        color: #048bf3;
        text-decoration: underline;
      }
    }
    @media (max-width: 1300px) {
      .nav--left {
        .logo {
          height: 7rem;
          padding: 0.5rem;
        }
      }

      .navlinks {
        &--link {
          font-size: 1.3rem;
        }
      }
    }

    @media (max-width: 980px) {
      height: 100%;
      flex-direction: column;
      position: relative;
      .navlinks {
        z-index: 200;
        position: relative;
        // flex-direction: column;
        background: var(--color-topbar);

        top: 0%;
        left: 0;
        width: 100%;
        height: fit-content;
        gap: 0rem;
        &--link {
          padding: 1rem;
          flex-direction: column;
          color: white;
          a {
            color: white;
          }
          width: 100%;
          border-bottom: 1px solid rgb(110, 110, 110);
          &__sub {
            width: max-content;
          }
        }
      }
    }

    @media (max-width: 750px) {
      .menu {
        display: inline-block;
      }
      .navlinks {
        flex-direction: column;

        display: none;

        &--link {
          align-items: flex-start;
          padding: 2rem 3rem;

          &__sub.active {
            top: 2%;
            background: var(--color-topbar);

            width: 100%;
            position: relative;
            li {
              padding: 1rem;
            }
          }
        }
        &.open {
          display: flex;
        }
      }
    }
  }
}
