.career-container {
  background-color: var(--color-neutral-bg);
}

.career {
  max-width: 140rem;
  margin: auto;
  padding: 2rem;
  .heading {
    font-weight: normal;
    text-align: left;
    line-height: 1.7;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.9;
    letter-spacing: 0.3px;
    color: var(--text-paragraph);
  }
  h3 {
    font-size: 2rem;
    color: var(--color-primary);
    text-align: left;
  }

  p a {
    color: var(--color-primary);
    font-weight: bold;
  }
  .job {
    max-width: 90rem;
    padding: 2rem;
    margin: 2rem;
    background: var(--bg-secondary);
  }

  .btn a {
    text-decoration: none;
    color: white;
  }
}
