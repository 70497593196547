.modal--container {
  position: relative;
}
.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #000000e0;
  top: 0;
  left: 0;
  z-index: 100;
  cursor: zoom-out;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;

  .close {
    position: absolute;
    color: rgb(252, 252, 252);
    font-size: 4rem;
    top: 1rem;
    right: 1rem;
    z-index: 102;
    cursor: pointer;
    &:hover {
      color: black;
    }
    svg {
      background: #00000041;
    }
  }
  img {
    max-width: 90vw;
    max-height: 90vh;
  }
}
