.simpleHeader {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url("../../../public/img/header/slideimage3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 4rem;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  word-spacing: 1rem;
  padding-bottom: 1rem;
  @media (max-width: 1380px) {
    font-size: 3rem;
  }
}
