.accounts-container {
  background-color: var(--color-neutral-bg);
}

.accounts {
  max-width: 140rem;
  padding: 2rem;
  margin: 0 auto;
  .heading-secondary {
    margin-top: 3rem;
    font-size: 2rem;
    color: var(--color-primary);
    text-align: left;
    line-height: 2;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.7;
    color: var(--black);
  }
  ul li {
    font-size: 1.5rem;
    list-style: circle;
    line-height: 2;
    margin: 0 2rem;
    letter-spacing: 0.3px;
    color: var(--black);
  }
}
