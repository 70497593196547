.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 140rem;
  margin: auto;
  flex-wrap: wrap;

  .tab {
    font-size: 1.2rem;
    background: transparent;
    cursor: pointer;
    padding: 0.5rem;
    width: 11rem;
    height: 6rem;
    text-align: center;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: var(--black);
    box-shadow: var(--box-shadow);
    &:hover {
      background: var(--color-primary);
      color: white;
    }
    &.active {
      background: var(--color-primary);
      color: white;
    }
  }
}
