.allist {
  display: flex;
  gap: 4rem;
  align-items: center;
}

.allist .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: 0.3px;
  }
  h3 {
    font-size: 2rem;
  }
}

.allist .img {
  width: 100%;
  max-width: 38rem;
}

.allist .img img {
  width: 100%;
  height: 25.5rem;
}

@media (max-width: 650px) {
  .allist {
    flex-wrap: wrap;
  }
}
