.contact {
  --toastify-color-progress-light: linear-gradient(to right, #025797, #87c9f0);

  --toastify-color-light: #052190;
  --toastify-toast-width: max-content;
  --toastify-text-color-light: red;

  --PhoneInputCountryFlag-height: 2rem;
  --PhoneInputCountryFlag-borderColor--focus: var(--color-primary);
  --PhoneInputCountrySelectArrow-color: var(--black);
  --PhoneInputCountrySelectArrow-opacity: 1;
  --PhoneInputCountrySelectArrow-width: 0.6rem;
  --PhoneInputCountryFlag-borderWidth: 2px;
}

.Toastify__close-button {
  opacity: 0.5;
}
.Toastify__close-button > svg {
  fill: white;
}
.toast-font-size {
  font-size: 1.7rem;
  text-transform: capitalize;
  color: white;
}
.Toastify__toast-container--top-center {
  top: 4rem;
}

.map iframe {
  width: 100vw;
}
.contact--container {
  background: var(--color-neutral-bg);
  width: 100%;
}
.contact {
  max-width: 135rem;
  margin: auto;
  padding: 4rem 0;
  width: 100%;
  display: flex;

  width: 100%;
  h2 {
    text-align: left;
  }

  .form {
    width: 100%;
  }

  .input-group {
    width: 100%;
    border-radius: 0.5rem;
  }

  .input-group.small {
    width: 49%;
    display: inline-block;
    @media only screen and (max-width: 1200px) {
      width: 48%;
    }
    @media only screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .input-group.small.email {
    margin-right: 1rem;
  }

  input,
  textarea {
    width: 100%;
    padding: 2rem;
    border: none;
    border: 1px solid var(--color-primary);
    background-color: var(--bg-secondary);
    color: var(--black);
    font-family: "PT sans";
    font-size: 1.4rem;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }
  label {
    font-size: 1.4rem;
    color: var(--text-paragraph);
    font-weight: bold;
    line-height: 2;
  }
  textarea {
    max-width: 100%;
    resize: none;
  }

  input::placeholder,
  textarea::placeholder {
    text-transform: capitalize;
    font-size: 1.4rem;
    font-family: "PT sans";
  }

  .input-btn {
    margin: 1rem 0;
    min-width: 20rem;
  }

  .PhoneInputCountryIcon {
    color: var(--color-primary);
  }

  .form-container {
    width: 100%;
    border-radius: 1rem 0 0 1rem;
    box-shadow: var(--form-shadow);
    padding: 5rem;
    background-color: var(--bg-secondary);
  }

  .form-header {
    font-size: 3rem;
    text-transform: uppercase;
    color: var(--black);
  }

  .contact-info {
    background: var(--color-primary-darker);
    width: 100%;
    border-radius: 0 1rem 1rem 0;
    box-shadow: var(--form-shadow);
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-info h2 {
    color: white;
  }
  .contact-info .links {
    border: 1px solid #052190;
    padding: 1rem;
    margin: 1rem;
    h3 {
      color: white;
      font-size: 1.9rem;
    }
    p {
      color: white;
      font-size: 1.7rem;
      padding: 1rem;
    }
  }
  .contact-info .links a {
    color: white;
    font-size: 1.7rem;
    display: block;
    line-height: 1.5;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    text-transform: none;
  }

  .contact-info .links a .icon {
    font-size: 2rem;
    margin-right: 1rem;
  }

  .contact-info .links a:hover {
    box-shadow: 5px 2px 5px var(--color-primary-lighter);
  }

  .contact-info .socialMedia {
    display: flex;
    gap: 2rem;
    border: 1px solid #052190;
    padding: 1rem;
    margin: 1rem;
  }

  .contact-info .socialMedia a .icon {
    width: 3rem;
    height: 3rem;
    border-radius: 40%;
    color: white;
  }

  .contact-info .socialMedia a .icon:hover {
    box-shadow: 5px 2px 5px var(--color-primary-lighter);
  }

  .errorMessage {
    font-size: 14px;
    padding: 3px;
    color: red;
    display: none;
  }

  input:invalid[submitted="true"] {
    border: 1px solid red;
  }

  textarea:invalid[submitted="true"] {
    border: 1px solid red;
  }

  input:invalid[submitted="true"] ~ .errorMessage {
    display: block;
  }

  .PhoneInput:has(> input:invalid[submitted="true"]) ~ .errorMessage {
    display: block;
  }

  .input-group select {
    background: var(--bg-secondary);
    color: var(--black);
  }

  textarea[submitted="true"] ~ .errorMessage {
    display: block;
  }
  textarea[submitted="true"] {
    border: 1px solid red;
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    flex-wrap: wrap;
  }

  .input-btn {
    width: 100%;
    margin: 0;
  }
}
