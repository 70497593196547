.dark {
  --color-neutral-light: #ebecee;
  --color-neutral-dark: #0d1d2e;

  --black: #fff;
  --white: #000;

  --text-paragraph: #cecbcb;
  /* --nav-bg: rgba(255, 255, 255, 0.9);
      
        --bg-color: #f3f3f3; */
  --border: 1px solid rgba(0, 0, 0, 0.2);
  --box-shadow: 0 0 3px #337ab7;
  --box-shadow-hover: 0 0 40px #337ab7;
  --form-shadow: 0px 15px 14px 0px #041320;
  --box-shadow-products: 5px 5px 5px #041320;
  --box-shadow-products-hover: 10px 10px 5px #041320;
  --grey-shade: #2e2e2e;
  --grey-lighter: #333;
  --color-primary: #048bf3;
  --color-primary-lighter: #3ba1de;
  --searchButton--box-shadow: rgba(122, 122, 122, 0.25) 0px 50px 100px -20px,
    rgba(255, 255, 255, 0.3) 0px 30px 60px -30px,
    rgba(161, 161, 161, 0.35) 0px -2px 6px 0px inset;
  --color-secondary: #052190;
  --color-topbar: #16214bdc;
  --color-primary-darker: #03145a;
  --nav-bg: rgba(16, 34, 54, 0.95);
  --color-neutral-bg: rgb(13, 27, 44);
  --bg-secondary: #09111a;
  --bg-tertiary: rgb(13, 27, 44);
  --image-gradient: linear-gradient(
    to top,
    rgba(11, 22, 184, 0.4),
    rgba(0, 0, 0, 0.8)
  );
}
