.searchPage {
  background: var(--bg-secondary);

  .searchHeader {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ),
      url("../../../public/img/header/slideimage1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 1rem;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 1.8rem;
      font-weight: normal;
      span {
        font-size: 2.5rem;
        padding: 0 0.5rem;
        font-weight: 600;
      }

      color: white;
      text-align: left;
    }
  }

  .search--fail {
    color: var(--text-paragraph);
    max-width: 130rem;
    margin: 0 auto;
    text-transform: capitalize;
    font-size: 1.7rem;
    text-align: center;
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
