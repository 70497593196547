.suppliers {
  width: 100%;
  background: var(--bg-secondary);
  &--container {
    max-width: 130rem;
    margin: auto;
    min-height: 55rem;
    padding: 6rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }
  }
  .tag {
    height: max-content;
  }

  .supplier--slider {
    width: 100%;

    overflow: hidden;
    .supplier--swiper {
      max-width: 80%;

      padding: 0rem 3rem;
      overflow: hidden;

      .swiper-button-prev {
        left: 0;
        color: var(--grey-lighter);
      }
      .swiper-button-next {
        right: 0;
        color: var(--grey-lighter);
      }

      .swiper-slide {
        margin: 1rem 0;
        width: 30rem;
        height: 30rem;

        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 70%;
          max-height: 70%;
          transition: all 0.5s;
        }

        &.swiper-slide-next,
        &.swiper-slide-active,
        &.swiper-slide-next + .swiper-slide {
          box-shadow: var(--box-shadow);
          &:hover {
            box-shadow: 0 0 4px var(--color-primary);
            img {
              transform: scale(1.15);
            }
          }
        }
      }
    }
  }
}
