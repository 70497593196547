.aboutPage {
  background: var(--color-neutral-bg);

  .header {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ),
      url("../../../public/img/header/uae.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    padding: 1rem;
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .heading {
      h2 {
        font-size: 2.5rem;
        color: white;
        text-align: left;
      }
    }
  }
  &--container {
    .vision {
      padding: 4rem 0;
      max-width: 130rem;
      margin: auto;
    }
    .mission {
      width: 100%;

      margin: auto;

      &--content {
        max-width: 130rem;
        margin: auto;
        .tag {
          margin: 5rem 0;
        }
        &--bottom {
          display: flex;
          background: var(--color-topbar);

          @media (max-width: 750px) {
            flex-wrap: wrap;
          }

          .about--left {
            width: 100%;
            display: flex;
            padding: 4rem 0;
            height: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          .about--right {
            background: var(--bg-secondary);
            width: 100%;
            height: 100%;
            padding: 4rem 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          p {
            max-width: 40rem;
            color: white;
            font-size: 1.8rem;
            letter-spacing: 1px;
            margin: 3rem 0;
          }
          .btn {
            margin-right: 3rem;
          }
        }
      }
    }

    p {
      color: var(--text-paragraph);
      font-size: 1.6rem;
      line-height: 1.4;
      padding: 0 1rem;
    }

    .circles {
      margin: 3rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      gap: 5rem;
      .circle {
        width: 30rem;
        height: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        text-align: center;
        padding: 3rem;
        border-radius: 50%;
        border: 1px dashed #999;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-top: 3px solid var(--color-primary);
          animation: spinner 20s linear infinite;
          border-radius: 50%;
        }

        h3 {
          font-size: 2rem;
          color: var(--color-primary);
        }
      }
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
