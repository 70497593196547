.topbar {
  background: var(--color-topbar);
  min-height: 4rem;
  width: 100%;
  display: flex;

  justify-content: space-around;
  &__left {
    display: flex;
    justify-content: space-between;
    width: 50%;
    &--element {
      display: flex;
      align-items: center;
      gap: 1rem;
      a,
      .icon {
        color: rgb(231, 229, 229);
        font-size: 1.4rem;
      }
      &:hover {
        a,
        .icon {
          color: #048bf3;
        }
      }
    }
  }

  &__right {
    .switch {
      display: flex;
      align-items: center;

      height: 100%;
      gap: 1rem;
      .icon {
        color: white;
        font-size: 1.4rem;
        &.active {
          color: rgb(255, 208, 0);
        }
      }
    }
  }
  @media (max-width: 980px) {
    flex-direction: column-reverse;
    align-items: flex-end;

    padding: 1rem;
    gap: 1rem;
    &__left {
      display: none;
    }
  }
}
