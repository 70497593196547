.home__w-section {
  width: 100%;
  background-color: var(--bg-secondary);
  padding: 1rem;
  &--container {
    width: 100%;
    max-width: 130rem;
    margin: 0 auto;
    padding: 4rem 0;
    .row {
      padding: 3rem 0;
    }
    .row1 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      max-width: 120rem;
      margin: auto;
      @media (max-width: 750px) {
        flex-wrap: wrap;
      }

      h3 {
        font-size: 1.4rem;
        text-transform: capitalize;
        color: var(--text-paragraph);
      }
      .boxes {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .box {
          padding: 1rem;
          width: max-content;
          display: flex;
          gap: 1rem;
          img {
            width: 5rem;
            height: 5rem;
          }
          .right {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            p {
              color: #999;
            }
          }
        }
      }

      .imgBox {
        width: 100%;
        margin: 3rem 0;
        display: flex;

        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .img {
          display: inline-block;
          width: 35rem;
          height: 25rem;
          position: relative;
          @media (max-width: 1050px) {
            width: 25rem;
            height: 15rem;
          }
          &::after {
            content: "";
            position: absolute;
            right: 3rem;
            bottom: -40px;
            border: 10px solid rgba(0, 0, 0, 0.103);
            border: 10px solid var(--grey-shade);
            display: block;
            width: 95%;
            height: 100%;
            transition: all 0.3s linear 0s;
            // z-index: -1;
          }
          img {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
          }
        }
        h3 {
          font-size: 1.7rem;

          position: relative;
          z-index: 1;
          width: max-content;
          color: var(--black);

          &:hover {
            color: #048bf3;
          }
        }
      }
    }
    p {
      width: 100%;
      color: var(--text-paragraph);
    }
  }

  p {
    font-size: 1.5rem;
    text-transform: capitalize;

    line-height: 1.5;
    letter-spacing: 0.5px;
  }
}
