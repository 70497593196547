.bgSection {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  /* url("../../../public/img/bgSection.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 650px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bgContainer {
  max-width: 140rem;
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  .bgContent {
    & > * {
      color: white;
      text-align: left;
      padding: 1rem;
    }

    p {
      line-height: 1.5;
      font-size: 1.6rem;
      letter-spacing: 1px;
    }
    .listContainer {
      width: 100%;
      display: flex;
      gap: 4rem;
      flex-wrap: wrap;
      ul {
        font-size: 2rem;
        line-height: 2;
        font-weight: bold;
        list-style: none;
        min-width: 30rem;
      }
    }
  }
  .partner--wrapper {
    width: 100%;
    height: 100%;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .partner {
    max-width: 50rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(31, 31, 31, 0.6);
    border-radius: 12px;
    border: 1px solid #048bf34d;
    transition: all 0.5s;
    .underline::before {
      background-color: rgba(31, 31, 31);
    }
    h2 {
      color: white;
    }
    img {
      width: 100%;
      max-width: 45rem;
      padding: 3rem;
      img {
        width: 100%;
      }
    }
    &:hover {
      box-shadow: 0 0 20px #337ab7;
    }
    @media (max-width: 1400px) {
      width: 80%;
    }
  }
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
}

@media (max-width: 675px) {
  .bgContent .listContainer {
    gap: 0;
  }
}
