.productHeader {
  width: 100%;
  height: 30rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../../public/img/header/slideimage1.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;

  div,
  h1 {
    width: 100%;
    height: 100%;
    max-width: 140rem;
    margin: auto;
    color: white;
  }
  h1 {
    text-align: center;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 2.5rem;
  }

  .breadCrumb {
    display: flex;
    text-transform: uppercase;
    gap: 1rem;
    font-size: 1.4rem;
    align-items: center;
    justify-content: flex-start;

    max-width: 140rem;
    margin: auto;
    padding-left: 4rem;

    a {
      border-right: 1px solid white;
      &:hover {
        color: grey;
      }
    }
    a,
    span {
      color: white;
      padding: 0 1rem;
    }
    @media (max-width: 1300px) {
      padding-left: 1rem;
    }
  }
}
