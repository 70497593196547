.social {
  position: fixed;

  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  ul {
    padding: 0px;
    transform: translate(-160px, 0);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    li {
      cursor: pointer;

      background: rgba(0, 0, 0, 0.7);
      width: 200px;
      box-shadow: 2px 5px 10px 0px #2e2f30;

      text-align: right;
      padding: 0.5rem;
      border-radius: 0 30px 30px 0;
      transition: all 1s;
      a {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: var(--text-paragraph);

        width: 100%;
        height: max-content;

        font-size: 1.6rem;
        font-weight: bold;

        .social-icon {
          margin-left: 10px;

          padding: 0.3rem;
          border-radius: 50%;
          width: 3.5rem;
          height: 3.5rem;
          font-size: 20px;
          background: var(--white);

          transform: rotate(0deg);
          transition: all 1s;

          &.twitter {
            color: #1da1f2;
          }
          &.facebook {
            color: #4267b2;
          }
          &.insta {
            color: #d6249f;
          }
        }
      }
      &:hover {
        // background: rgba(248, 248, 248, 0.5);
        transform: translate(100px, 0);
        box-shadow: 1px 1px 10px #337ab7;

        a {
          span {
            color: #048bf3;
          }
          .social-icon {
            box-shadow: var(--box-shadow);
            background: linear-gradient(to bottom, #999999 0%, #e1e1e183 100%);
            transform: rotate(360deg);
            transition: all 1s;
          }
        }
      }
    }
  }
  @media (max-width: 1300px) {
    top: 98%;
    transform: translateY(-98%);
  }
}
