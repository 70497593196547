.cards {
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.card {
  background-color: var(--bg-secondary);
  margin-top: 3rem;
  height: fit-content;
  min-height: 46rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 30rem;
  border: 1px solid var(--bg-secondary);
  box-shadow: var(--box-shadow);
  border-radius: 0.6rem;
  overflow: hidden;
}

.card:hover {
  box-shadow: var(--box-shadow-hover);
}

.card img {
  flex: 1;
  width: 100%;
}

.card .content {
  flex: 1;
  padding: 0 1.6rem;
}

.card .content h3 {
  margin-bottom: 1.6rem;
  color: var(--color-primary);
  font-size: 1.8rem;
}

.card .content p {
  color: var(--text-paragraph);
  margin-bottom: 0.6rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: left;
  letter-spacing: 0.1px;
}

@media (max-width: 1320px) {
  .card {
    min-width: 40rem;
  }
}
