.serviceList {
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: var(--color-neutral-bg);
}

.serviceList-container {
  max-width: 140rem;
  padding: 4rem 1rem;
  width: 100%;
}

.serviceList-container p {
  max-width: 90rem;
  line-height: 2;
  letter-spacing: 0.3px;
  color: var(--text-paragraph);
  font-size: 1.5rem;
}

.serviceList-container .lists {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  max-width: 90rem;
  margin-top: 3rem;
}

.serviceList-container .heading-secondary {
  text-align: left;
  color: var(--color-primary);
}
