.service {
  width: 100%;
  display: flex;
  justify-content: center;

  background: var(--bg-tertiary);
  &--container {
    max-width: 150rem;
    padding: 3rem;
    width: 100%;

    .underline::before {
      background: var(--bg-tertiary);
    }
  }
}
