.headerSwiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .swiper-pagination {
    --swiper-pagination-right: 3%;
    --swiper-pagination-left: auto;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 3px solid #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: content-box;
    opacity: 1;
    background: linear-gradient(to bottom, #999999 0%, #e1e1e1 100%);
  }

  .swiper-pagination-bullet-active {
    background: #666666;
  }

  .slide {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.slide1 {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.7)
        ),
        url("../../../public/img/header/slideimage1.jpg");
    }
    &.slide2 {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.7)
        ),
        url("../../../public/img/header/slideimage2.jpg");
    }

    &.slide3 {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.7)
        ),
        url("../../../public/img/header/slideimage3.jpg");
    }
    &.slide4 {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.7)
        ),
        url("../../../public/img/header/slideimage4.jpg");
    }
    .content {
      width: 100%;
      height: 100%;
      max-width: 130rem;
      color: white;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      justify-content: center;
      align-items: flex-start;

      h1,
      p {
        margin-left: 4rem;
        @media (max-width: 1300px) {
          margin-left: 0rem;
        }
      }

      h1 {
        padding-left: 1rem;
        font-size: 6rem;
        font-weight: 400;
        text-transform: capitalize;
        max-width: 40rem;

        @media (max-width: 1380px) {
          font-size: 5rem;
        }

        @media (max-width: 750px) {
          font-size: 3.5rem;
        }
      }
      p {
        padding-left: 1rem;
        font-size: 2rem;
        line-height: 1.5;
        text-transform: capitalize;
        max-width: 40rem;
      }
      .btn {
        margin-left: 5rem;
        @media (max-width: 1300px) {
          margin-left: 1rem;
        }
      }
    }
  }
}
