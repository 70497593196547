.main-cast-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  /* url("../../../public/img/bifold-doors.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 641px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-cast {
  max-width: 150rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.main-cast .content {
  color: white;
  padding: 4.5rem;
  border: 1px solid white;
  max-width: 58.5rem;
  margin: 2rem 1rem;
  h2 {
    color: white;
  }
  p {
    line-height: 2;
    text-align: justify;
    letter-spacing: 1px;
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: none;
  }
}

.main-cast .content p {
  line-height: 2.7;
  text-align: justify;
}
