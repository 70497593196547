.section {
  width: 100vw;
  display: flex;
  justify-content: center;
  background: var(--bg-secondary);
}

.sectionContainer {
  max-width: 145rem;
  padding: 2rem 0;
  width: 100%;
  margin: 2rem;
}

.sectionContainer .flex-1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5rem;
  margin-bottom: 6rem;
}

.sectionContainer .flex-content {
  margin: 0;
  align-self: center;
  border: 1px solid var(--color-primary);
  width: 100%;
  height: 100%;
  min-width: 40rem;
  color: var(--color-primary);
  padding: 4.5rem;

  max-width: 58.5rem;
  p {
    line-height: 2;
    text-align: justify;

    font-size: 1.5rem;
    font-weight: 400;
    text-transform: none;
  }
}

.sectionContainer .img {
  max-width: 590px;
  max-height: 531px;
}

.sectionContainer .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sectionContainer .flex-content p {
  line-height: 2.7;
  text-align: justify;
}
