.gardenRooms {
  background: var(--bg-secondary);
  .simpleHeader {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),
      url("../../../public/img/header/slideimage4.jpg");
  }
  &--container {
    width: 100%;
    max-width: 140rem;
    margin: 0 auto;
    padding: 4rem 1rem;
    gap: 4rem;
    .row {
      background: var(--color-neutral-bg);
      display: flex;
      box-shadow: var(--box-shadow);
      margin: 2rem 0;
      justify-content: center;
      @media (max-width: 750px) {
        flex-wrap: wrap;
      }

      .content {
        color: var(--text-paragraph);
        ul {
          li {
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            line-height: 2;
            letter-spacing: 1px;
          }
        }
        h2 {
          text-align: left;
          font-size: 2.5rem;
        }
        width: 100%;

        padding: 3rem;

        p {
          margin: 2rem 0;
          font-size: 1.5rem;
          line-height: 1.5;
          letter-spacing: 1px;
          text-align: justify;
        }
        .btn {
          margin: 3rem 0;
        }
      }

      .img {
        width: 100%;

        background: #000;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
