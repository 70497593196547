.search {
  z-index: 50;
  position: relative;
  max-width: 30rem;
  width: 100%;
  margin: auto;

  .alertBox {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: rgb(112, 25, 25);
    .warning--icon {
      color: rgb(170, 140, 7);
    }
  }

  &--box {
    border: 1px solid var(--grey-lighter);
    background: var(--bg-secondary);
    border-radius: 2rem;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    &:has(input:focus) {
      border: none;
      box-shadow: var(--box-shadow);
    }

    input {
      font-size: 1.4rem;
      border: none;
      outline: none;
      background: transparent;
      padding: 0 1rem;
      color: var(--text-paragraph);
    }
    .searchClear {
      cursor: pointer;
      font-size: 1.2rem;
      color: var(--text-paragraph);
      &:hover {
        color: var(--black);
      }
    }
    .searchButton {
      box-shadow: var(--searchButton--box-shadow);
      display: flex;
      align-items: center;
      background: none;
      border: none;
      border-radius: 1rem;
      cursor: pointer;
      gap: 2px;
      padding: 4px;
      color: #777;

      .searchIcon {
        font-size: 1.4rem;
      }

      &:hover {
        border: 1px solid #999;
        .searchIcon {
          color: var(--black);
        }
      }
    }
  }

  &--dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    height: max-content;
    padding: 0.5rem;
    background: var(--color-neutral-bg);
    border: 1px solid var(--grey-lighter);
    margin-top: 0.5rem;
    span {
      display: block;
      font-size: 1.3rem;
      padding: 0.5rem 0;
      cursor: pointer;
      color: var(--text-paragraph);
      &.active {
        background: var(--grey-lighter);
      }
      &:hover {
        background: var(--grey-lighter);
      }
    }
    &__empty {
      border: none;
      padding: 0;
      margin: 0;
    }
  }
}
