.photo--gallery {
  background: var(--color-topbar);
  img {
    border: 1px solid var(--white);
    cursor: pointer;
    transition: all 0.5s;
    animation: image 0.3s ease-in;
    &:hover {
      filter: brightness(70%);
      transform: translateY(-2px);
    }
  }
}

button {
  background: none;
  border: none;
  height: fit-content;

  cursor: pointer;
  &.arrow {
    position: fixed;
    z-index: 200;

    &--left {
      top: 50%;
      left: 1rem;
    }
    &--right {
      top: 50%;
      right: 1rem;
    }
    .icon {
      font-size: 3rem;
      color: white;
      &:hover {
        color: rgb(201, 197, 197);
      }
    }
  }
  &:disabled,
  button[disabled] {
    .icon {
      color: rgba(187, 187, 187, 0.289);
      &:hover {
        color: rgba(187, 187, 187, 0.289);
      }
    }
    cursor: default;
  }
}
