:root {
  --color-primary: #052190;
  --color-primary-darker: #061c75;
  --color-primary-lighter: #0437b4;
  --color-secondary: #048bf3;
  --color-secondary-lighter: #3ba1de;
  --color-secondary-lighter: #a4c2d3;
  --white: #fff;
  --black: #000;
  --grey-shade: #ebebeb;
  --grey-lighter: #bdbdbd;
  --color-topbar: #333333;
  --color-neutral-bg: #ebecee;
  --bg-secondary: white;
  --text-paragraph: #404e5c;
  --border: 1px solid rgba(0, 0, 0, 0.2);
  --box-shadow: 0 0 3px #2121218c;
  --box-shadow-hover: 0 0 30px #2121218c;
  --form-shadow: 0px 15px 14px 0px rgb(202 202 202);
  --box-shadow-products: 5px 5px 5px #ccc;
  --box-shadow-products-hover: 10px 10px 5px #ccc;
  --searchButton--box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  --nav-bg: rgba(255, 255, 255, 0.95);
  --bg-tertiary: #ebf5f8;
  --image-gradient: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.3),
    rgba(8, 8, 8, 0.3)
  );
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: auto;
}

body {
  font-family: "PT sans", sans-serif;
  width: 100vw;
}
h1,
h2,
h3,
h4 {
  font-family: "Merriweather", "PT sans", sans-serif;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}

.btn {
  min-width: max-content;
  width: 130px;
  min-height: 4.5rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: "Merriweather", "PT sans", sans-serif;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
  overflow: hidden;
  background: var(--color-primary);
  color: #fff;
  transition: all 0.5s ease;
  border-radius: 1rem;
  &::after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  &:hover {
    background: transparent;
    z-index: 0;
    transition: all 0.5s ease;

    // color: var(--color-primary);
    &::after {
      background: var(--color-primary-lighter);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  &--main {
    font-size: 1.8rem;
    @media (max-width: 750px) {
      font-size: 1.6rem;
      padding: 1rem;
    }
    &:hover {
      color: black;
      &::after {
        background: #fff;
      }
    }
  }
}

.tag {
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 130%;
  background: var(--color-secondary);
  letter-spacing: 1.5px;
  padding: 1rem 1.5rem;
  margin-bottom: 20px;
  width: 100%;
  max-width: max-content;
  &::after {
    content: "";
    position: absolute;
    display: block;
    border-bottom: 2px solid var(--color-secondary);
    width: 590px;
    margin-left: -590px;
    margin-top: -7px;
    padding-top: 15px;
  }
}

h2 {
  font-size: 3.5rem;
  text-transform: capitalize;
  text-align: center;
  margin: 1rem 0;
  color: var(--color-primary);
  padding-bottom: 0.5rem;
  @media (max-width: 750px) {
    font-size: 2.8rem;
  }
}

.heading {
  position: relative;

  .underline {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 18rem;
    height: 1px;
    background: #555;
    margin: auto;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: 50%;

      width: 1.5rem;
      height: 1.5rem;
      background: var(--bg-secondary);
      transform: rotate(45deg) translateX(-50%);
      border-bottom: 1px solid var(--grey-lighter);
      border-right: 1px solid #555;
    }
  }
}
.sticky-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  transition: transform 0.2s linear;
  &:hover {
    .whatsapp-icon {
      box-shadow: var(--box-shadow-hover);
    }
  }
  .whatsapp-icon {
    color: white;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background: #25d366;
    padding: 1rem;
    box-shadow: var(--box-shadow);
  }
}

.top__btn {
  position: fixed;
  bottom: 8rem;
  right: 2rem;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  z-index: 100;
  cursor: pointer;
  background: var(--color-neutral-bg);
  border: 1px solid grey;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s;
  &--icon {
    font-size: 2rem;
    color: var(--color-primary);
    transition: 0.2s all;
  }
  &:hover {
    .top__btn--icon {
      transform: scale(1.1);
    }
  }
  &.on {
    visibility: visible;
    opacity: 1;
    transition: all 0.8s;
  }
}

@media (max-width: 1049px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 300px) {
  html {
    font-size: 45%;
  }
}
