.products-container {
  background: var(--color-neutral-bg);
  background: linear-gradient(
    to bottom,
    var(--bg-secondary),
    var(--color-neutral-bg)
  );
  width: 100vw;
}

.products {
  display: flex;
  max-width: 140rem;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  margin: auto;
  padding: 9rem 0;

  /* flex-wrap: wrap; */
  align-items: center;
}

.products.direction {
  flex-direction: row;
}

.products .product_details {
  width: 100%;
  padding: 2rem;
}

.product_details .heading-secondary {
  color: var(--color-primary);
  text-align: left;
}

.product_details p {
  color: var(--text-paragraph);
  line-height: 1.7;
  letter-spacing: 0.2px;
  text-align: justify;
  font-size: 1.5rem;
}

.products_img {
  width: 100%;

  display: flex;
  justify-content: center;
}
.products .products_img .img {
  max-width: 403px;
  height: 377px;
  box-shadow: var(--box-shadow-products);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.2s;
  img {
    width: 100%;
    height: 100%;
  }
  &:hover {
    box-shadow: var(--box-shadow-products-hover);
  }
}

@media (max-width: 750px) {
  .products {
    flex-wrap: wrap;
    padding: 4rem 0;
  }
}

@media (max-width: 300px) {
  .products .products_img img {
    height: 177px;
  }
}
