.gallery--container {
  padding: 4rem 0;
  width: 100%;
  background: var(--color-neutral-bg);

  background: var(--bg-tertiary);
  section {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    .heading .underline::before {
      background: var(--bg-tertiary);
    }
  }

  .gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 4rem 1rem;
    background: var(--color-topbar);
    .btn {
      width: 20rem;
    }
    .images {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4rem;
      flex-wrap: wrap;
      .img {
        width: 40rem;
        height: 31rem;
        position: relative;
        transition: 0.5s ease;
        cursor: zoom-in;
        box-shadow: var(--box-shadow);
        .imageHeading {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 1rem;
          font-size: 2rem;
          color: white;
          text-transform: capitalize;
          font-weight: 500;
          opacity: 0;
          transition: position 0.5s ease;
        }
        img {
          width: 100%;
          height: 100%;
          animation: image 0.3s ease-in;
          transition: 0.5s ease;
        }
        .overlay {
          position: absolute;
          width: 100%;
          height: 0%;
          left: 0;
          bottom: 0;
          transition: 0.5s ease;
          background: linear-gradient(
            transparent 0%,
            transparent 50%,
            rgba(0, 0, 0, 0.347) 50%,
            rgb(0, 0, 0)
          );
        }
        &:hover {
          .overlay {
            height: 100%;
          }
          .imageHeading {
            opacity: 1;
          }
        }
        @media (max-width: 1450px) {
          width: 28rem;
          height: 20rem;
        }
        @media (max-width: 750px) {
          width: 18rem;
          height: 13rem;
        }
        @media (max-width: 450px) {
          .overlay {
            height: 100%;
          }
          .imageHeading {
            opacity: 0.5;
          }
          width: 28rem;
          height: 22rem;
        }
      }
    }
  }
}

@keyframes image {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
