$anim-duration: 4; // in seconds
$anim-speed: 0.7; // in seconds

.quotation {
  background: var(--color-neutral-bg);
  border-top: 1px solid #ccc;
  &--container {
    max-width: 130rem;

    margin: 9rem auto 0;
    width: 100%;
    min-height: 50vh;
    padding: 1rem;
    .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: var(--text-paragraph);
    }

    .ant-steps-item-title {
      color: var(--text-paragraph) !important;
    }
  }
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .field {
      max-width: 100rem;
      width: 100%;
      box-shadow: var(--box-shadow);
      border-radius: 2rem;
      align-items: center;
      background: var(--bg-secondary);

      margin: 4rem 0;

      .buttons {
        display: flex;
        justify-content: center;
        gap: 3rem;
      }
      label {
        color: var(--black);
      }
    }

    .form--container {
      width: 100%;

      padding: 3rem;

      .form--group {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 600;
        }

        input,
        textarea {
          border: none;
          width: 100%;
          outline: none;

          border-bottom: 1px solid var(--grey-lighter);

          padding: 1rem;

          color: var(--text-paragraph);
          background: transparent;
          font-family: "Merriweather", "PT Sans", sans-serif;
          &::placeholder {
            color: var(--grey-lighter);
            font-size: 1.2rem;
            font-family: "Merriweather", "PT Sans", sans-serif;
            text-transform: capitalize;
          }
        }

        label,
        input,
        textarea {
          font-size: 1.4rem;
          color: var(--text-paragraph);
        }
      }
    }

    .measure--wrapper {
      display: flex;

      justify-content: center;
      overflow: hidden;
      min-height: 50rem;
      @media (max-width: 750px) {
        flex-direction: column;
      }
      .form--container {
        border: 1px solid var(--grey-lighter);
        max-width: 40rem;
        margin: 0 4rem;
      }
      .gridImage {
        width: 100%;
        min-height: 50rem;
        background: #0d1b2c;
        background: var(--color-primary-darker);

        display: flex;
        align-items: center;
        justify-content: center;
        .board {
          --grid-col: 1;
          --grid-row: 1;
          --grid-border: 3;
          /* Start with 4x4 */
          display: grid;
          width: 25rem;
          height: 25rem;
          grid-template: repeat(var(--grid-row), 1fr) / repeat(
              var(--grid-col),
              1fr
            );

          border: solid transparent;
          border-width: var(--grid-border);

          background: linear-gradient(
              to right,
              var(--color-primary-darker),
              var(--color-primary-darker)
            ),
            linear-gradient(to bottom, #bcc6cc, #eee, #bcc6cc);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          position: relative;
          overflow: hidden;

          &::before {
            z-index: 10;
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              120deg,
              transparent,
              rgba(255, 255, 255, 0.2),
              transparent
            );
          }
        }
        .board-anime {
          &::before {
            animation: shine #{$anim-duration}s ease-in-out infinite;
          }
        }

        .square {
          border: solid transparent;
          border-width: var(--grid-border);
          backdrop-filter: blur(2px);

          box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
          background: linear-gradient(
              -70deg,
              rgba(50, 82, 123, 1) 15%,
              rgba(38, 75, 123, 1) 35%,
              rgba(25, 68, 123, 1) 50%,
              rgba(13, 61, 123, 1) 65%,
              rgba(1, 54, 123, 1) 85%,
              rgba(0, 54, 123, 1) 100%
            ),
            linear-gradient(to bottom, #8d9499, #dfdcdc, #a1aaaf);
          background-clip: padding-box, border-box;
          background-origin: padding-box, border-box;
          display: grid;
          place-items: center;
          font-size: 40px;
        }
      }
    }
  }
  .type__wrapper {
    align-items: center;

    .ant-select-arrow {
      color: var(--grey-lighter);
    }
    .ant-select-selection-item {
      color: var(--black);
    }

    .ant-select-selection-placeholder {
      color: var(--grey-lighter);
    }

    .ant-select-selector {
      background: transparent;
    }
  }
  .type__wrapper,
  .description--wrapper {
    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 3rem;
    padding: 3rem;
    .form--container .form--group {
      textarea {
        border: 1px solid var(--grey-lighter);
      }
    }
  }
  .details--wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    gap: 2rem;
    padding: 3rem 1rem;
    .details--box {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      padding: 2rem 1rem;
      h3 {
        font-size: 2rem;
        color: var(--color-primary);
      }
      p {
        font-size: 1.5rem;
        color: var(--text-paragraph);
        span {
          font-weight: 600;
        }
      }
    }
    .form--container {
      max-width: 38rem;
    }
  }
}
.ant-select-item-option-content {
  color: var(--text-paragraph);
}
@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  #{(calc($anim-speed / ($anim-duration + $anim-speed)) * 100%)},
  100% {
    left: 100%;
    transition-property: left;
  }
}
