.kitchen {
  width: 100vw;

  background-color: var(--color-neutral-bg);
  .simpleHeader {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),
      url("https://images.unsplash.com/photo-1602028915047-37269d1a73f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8a2l0Y2hlbiUyMGNhYmluZXRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60");
  }
}
.kitchen .img {
  margin: 0 auto;
  padding: 5rem 0;
  max-width: 140rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
}

.kitchen .img img {
  padding: 1rem;
  max-width: 30rem;
  height: 35rem;
  transition: transform 500ms ease;
}
.kitchen img:hover {
  transform: scale(1.1);
  transform-style: preserve-3d;
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
  box-shadow: 0 0 20px #337ab7;
}
