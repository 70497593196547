.footer {
  width: 100vw;
  display: flex;
  justify-content: center;
  background: var(--color-primary-darker);
  position: relative;
}

.footer-container {
  max-width: 140rem;
  padding: 4rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  color: white;
}

.footer-container .row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.footer-container .row .col {
  min-width: 25rem;
  a {
    h3 {
      &:hover {
        color: #048bf3;
      }
    }
  }
  h3 {
    color: white;
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: underline;
    margin-bottom: 1rem;
  }
}

.footer-container .row .col .sub-heading {
  text-transform: none;
}

.footer-container .row .col ul li {
  list-style: none;
  transition: all 0.3s ease-in;
}

.footer-container .row .col ul li a {
  list-style: none;
  font-size: 1.5rem;
  line-height: 1.8;
  color: #e6e6e6;
}

.footer-container .row .col ul li a:hover {
  border-bottom: 1px solid #048bf3;
  color: #048bf3;
}

@media (max-width: 1000px) {
  .footer-container .row {
    gap: 3rem;
  }
}
